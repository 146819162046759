import React, { useEffect } from 'react'
import { Constants } from './helpers/Constants';
import { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import loadingImg from "../Images/Loading.gif"
import { IResponse } from './Interfaces/Interfaces';
import protectedAxiosInstance from "./helpers/api";


 const ReportCatalog = () => {
  const [defaultView, setDefaultView] = useState<string>("Search Report");
  const [data, setData] = useState<any>({
    Views: {
      CPP: true,
      'GTM&PP': false,
      CD: false,
      ISV: false,
      MSP: false,
      SI: false,
      PPR: false
    }
  });
  const [headers, setHeaders] = useState(["Search Report"])
  const [isloading, setIsLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [uri, setUri] = useState('');
  const [MPL_User, setMPL_User] = useState(Constants.MPL_USER_HISTORY);
  const [search, setSearch] = useState('');
  const [searchResultCount, setSearchResultCount] = useState<any>(null);
  const [tileText, setTileText] = useState<any>([]);
  const [URLsData, setURLsData] = useState<any>();

  // const switchReportView = (filter: string, currView: string, eventInfo: any) => {
  //   if (currView !== data[filter]) {
  //     setData((prevData: any) => ({
  //       ...prevData,
  //       [filter]: currView
  //     }));
  //   }
  //   if (filter === 'defaultView') {
  //     changeDefaultView(currView);
  //   }
  // };

  // const changeDefaultView = (viewName: string) => {
  //   if (!data.Views[viewName]) {
  //     setData((prevData: any) => ({
  //       ...prevData,
  //       Views: {
  //         ...prevData.Views,
  //         [viewName]: true
  //       }
  //     }));
  //   }
  // };

  const loadExternalFilesData = (response: IResponse) => {
      if (response && response.status === Constants.HTTP_STATUS.OK) {
        setURLsData(response.data.Table);
          setTileText(response.data.Table);
        setDefaultView("Search Report");
        setIsError(false);
        setIsLoading(false);
      }
  };

  // const loadReportGrid = (event: any, navItem: any) => {
  //   setSearch("");
  //   const gridName = navItem.gridName;
  //   setDefaultView(gridName);
  // };

  // const navigationLinkClicked = (navLink: string) => {
  //   setDefaultView(navLink);
  // };

  const searchTiletext = (reportName: string) => {
    setSearchResultCount(0);

    if (reportName === "Search Report") {
      tileText.forEach((result: any) => {
        if (result.TileText.toLowerCase().includes(search.toLowerCase()) || result.Description.toLowerCase().includes(search.toLowerCase())) {
          setSearchResultCount((prevCount: any) => prevCount + 1);
        }
      });
    } else {
      if (!URLsData[reportName].hasSubGroups) {
        URLsData[reportName].Reports.forEach((report: any) => {
          if (report.TileText.toLowerCase().includes(search.toLowerCase())) {
            setSearchResultCount((prevCount: any) => prevCount + 1);
          }
        });
      } else {
        URLsData[reportName].SubGroups.forEach((subGroup: any) => {
          subGroup.Reports.forEach((report: any) => {
            if (report.TileText.toLowerCase().includes(search.toLowerCase())) {
            }
          });
        });
      }
    }
  };

  const getURL = (reportUrl: string):string => {
    let url = reportUrl;

    if(reportUrl.includes('ftbi.microsoft.com') || reportUrl.includes('ftbiuat.microsoft.com')){
      let reportId = reportUrl.split("=")[1];
      let parent
      if(reportId.includes("_")){
          parent = reportId.split('_')[0]
          url =  window.location.origin + "/view?parent=" + parent + "&tileId=" + reportId
      }else{
          url = window.location.origin + "/view?parent=" + reportId
      }
    }
    return url;
  }

  const LoadData = async () => {
    try {
      const response = await protectedAxiosInstance.get(`${window.location.origin}/api/Service/GetReportLibraryConfiguration`,{
        withCredentials: true,
      })
      loadExternalFilesData(response as IResponse)
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error)
    }
  }

  useEffect(()=>{
    LoadData() 
  },[])


  return (
    <div className="ReportContainer">
      {isloading && <div className="LoadingReports">
        <center><img className="loadingimg" src={loadingImg} alt="Loading" /></center>
      </div>}
      {iserror && <div className="ErrorReports">
        <center>
          <h2>:(</h2>
          <h4>Something Went Wrong, <span className="TryAgainLink" onClick={() => LoadData()}>Try Again</span></h4>
        </center>
      </div>}
      {!isloading && !iserror && <div className="ExternalReportContainer">
        <div className="ReportLinksWrapper">
          {URLsData && headers.map((navLink: any, index: number) => (
            navLink === defaultView && <div className="Reportholder" key={index}>
              <div className="ReportLibraryTemplate">
                <div className="Group">
                  <h3 className="GroupTitle">{navLink}</h3>
                  <div className="inputContainer catalogContainer">
                    <input
                      type="text"
                      placeholder="Search Reports by Name or Keyword"
                      aria-label="Search Reports"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="SearchBoxInput SearchBoxInputBox CatalogSearchbox"
                      onKeyUp={(e) => {

                        searchTiletext(navLink);
                      }}
                    />
                    <div className="searchMagnifierIcon" id="CatalogSearchIcon" title="Search Partners"></div>
                  </div>

                  <div className="GroupDescription CommonValues"
                    dangerouslySetInnerHTML={{ __html: URLsData['Description'] }}
                    style={{ display: URLsData['Description'] !== '' ? 'block' : 'none' }}>
                  </div>
                  <div className="GridHolder" style={{ display: defaultView === 'Search Report' ? 'block' : 'none' }}>
                    <div className="GridHolder CommonValues">
                      <table className="table">
                        <thead>
                          <tr className="tableHeaderRow" >
                            <th style={{background: '#505050', color: 'white'}} className="tableHeaderPersona">Persona</th>
                            <th style={{background: '#505050', color: 'white'}} className="tableHeaderTitleText">Title</th>
                            <th style={{background: '#505050', color: 'white'}} className="tableHeaderDescription">Description</th>
                            <th style={{background: '#505050', color: 'white'}} className="tableHeaderTypeOfReport">Type of Report</th>
                            <th style={{background: '#505050', color: 'white'}} className="tableHeaderContact">Contact</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tileText && tileText.map((report: any) => (
                            <tr key={report.id} className="tableRow" style={{ display: search.length === 0 || report.TileText.toLowerCase().includes(search.toLowerCase()) || report.Description.toLowerCase().includes(search.toLowerCase()) ? 'table-row' : 'none' }}>
                              <td className="tableRowPersonaData">{report.Persona}</td>
                              <td className="tableRowTitleTextData">
                                <div style={{ display: report.TileText === MPL_User ? 'block' : 'none' }}>
                                  <a href={`${report.isFile ? '/api/download/ReportLibraryFile?filename=' : ''}${report.ReportURL}${uri}`} className="ReportLink" target="_self">{report.TileText}</a>
                                </div>
                                <div style={{ display: report.TileText !== MPL_User ? 'block' : 'none' }}>
                                  {/* <a href={`${report.isFile ? '/api/download/ReportLibraryFile?filename=' : ''}${report.ReportURL}`} className="ReportLink" target="_self">{report.TileText}</a> */}
                                  <a href={`${report.isFile ? '/api/download/ReportLibraryFile?filename=' : ''}${getURL(report.ReportURL)}`} className="ReportLink" target="_self">{report.TileText}</a>
                                  <span style={{ display: report.ReportURL === '#' ? 'inline' : 'none' }}>{report.TileText}</span>
                                </div>
                              </td>
                              <td className="tableRowDescriptionData">{report.Description}</td>
                              <td className="tableRowTypeOfReportData">{report.IsPowerBI}</td>
                              <td className="tableRowContactData">
                                <span>{report.ContactEmail}</span>
                                <div>&nbsp;</div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {search.length !== 0 && searchResultCount === 0 && <div className="ErrorData ErrorDataMessageDynamics">
                        <center>
                          <div className="SearchResultsEmoticon">No Data :(</div>
                        </center>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>}
    </div>
  )
}
export default ReportCatalog;



