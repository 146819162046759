

import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import "./OCPLanding.css"; // Import global styles
import newsletterImg from "../../Images/img/newsletter.png";
import trainImg from "../../Images/img/train.png";
import newspaperIcon from "../../Images/img/newslettericon.png";
import trainingIcon from "../../Images/img/trainingicon.png";
import { Constants } from "../helpers/Constants";
import Loading from '../../Images/Loading.gif'
import { useNavigate } from "react-router-dom";



interface props {
  isLandingPageDataLoaded: boolean;
  latestNewLetterLinks: any[];
}

const LeftComponent: React.FC<props> = ({isLandingPageDataLoaded, latestNewLetterLinks}) => {
  const navigate= useNavigate();
  return (
    <Card className="left-component line" style={{ border: "none", paddingLeft: "1.4rem"}}>
      <Row className="align-items-center newsletter">
        <Col md={8}>
          <h5 className="d-flex align-items-center">
            <img src={newspaperIcon} alt="Newsletter Icon" className="icon" style={{scale: "1.4", marginLeft: "2px"}}/>
            Recent Business Analytics Newsletter
          </h5>
          {!isLandingPageDataLoaded && (
            <div className="NoNotification">
              <img className="landingpage-loadingIcon" src={Loading} />
            </div>
          )}

          {latestNewLetterLinks.length > 0 && (
            <div className="carousel slide carousel-fade" id="slider">
              <div className="carousel-inner">
                {latestNewLetterLinks.map((link: any, index: number) => (
                  <div
                    key={index}
                    className={`newItemsSection carousel-item item ${
                      index === 0 ? "active" : ""
                    }`}
                  >
                    <div className="row" id="newsletterRow">
                      <div
                        className="newLinksContainer col-md-4"
                        style={{ width: "100%" }}
                      >
                        <span style={{ fontSize: "medium" }}>
                          <b>{link.Date}</b>
                        </span>{" "}
                        <br />
                        <div className="tileText newLink">
                          {link.URL.length > 0 ? (
                            <a
                              className="newlink"
                              href={link.URL}
                              target="_blank"
                              title={link.Title}
                            >
                              <span
                                className="cursorPointer"
                                title={link.Title}
                                style={{ fontSize: "medium" }}
                              >
                                {link.Title}
                              </span>
                            </a>
                          ) : (
                            <span
                              className="cursorPointer normalHeadingLink newlink"
                              title={link.Title}
                            >
                              {link.Title}
                            </span>
                          )}
                        </div>
                        <div className="descListContainer">
                          <div className="descriptionContainer">
                            <div style={{fontSize: "14px"}}>{link.Description}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <a onClick ={() =>{navigate("../Pages/Newsletters")}} className="view-all-link">
            <b>View all archived newsletters &gt;</b>
          </a>
        </Col>
        <Col md={4}>
          <img src={newsletterImg} alt="Newsletter" className="img-fluid" />
        </Col>
        
      </Row>
      <hr style={{ width: "100%" }} />

      <Row className="align-items-center mt-2 training">
      
      
        <Col md={8}>
          <h5 className="d-flex align-items-center">
            <img src={trainingIcon} alt="Training Icon" className="icon" />
            Training Spotlight
          </h5>
          <p>
            <b>How to get access to Power BI Reports?</b>
          </p>
          <ul className="bullet">
            <li>
              <a
                href={Constants.JOIN_SG_URL}
                target="_blank"
                className="text-link "
              >
                Join required SGs
              </a>
            </li>
            <li>
              <a
                href={Constants.WORKSPACE_URL}
                target="_blank"
                className="text-link  "
              >
                Join Power BI Workspace
              </a>
            </li>
          </ul>
          <a onClick ={() =>{navigate("../Pages/Training")}}className="view-all-link">
            <b>View all training modules &gt;</b>
          </a>
        </Col>
        <Col md={4}>
          <img src={trainImg} alt="Training" className="img-fluid" />
        </Col>
      </Row>
    </Card>
  );
};

export default LeftComponent;
