import React, { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import {
  TextField,
  PrimaryButton,
  DefaultButton,
  DetailsList,
  DetailsListLayoutMode,
  IColumn,
  SelectionMode,
  IDetailsListStyles,
  IconButton,
} from "@fluentui/react";
import { Save16Regular, Dismiss16Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";
import protectedAxiosInstance from "./helpers/api";
import { Edit16Regular } from "@fluentui/react-icons";
import "../Styles/Admin.css";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
initializeIcons(); 

interface ReportDetails {
  ID: number;
  TileText: string;
  Description: string;
  ReportURL: string;
  IsPowerBI: string;
  ContactEmail: string;
  Persona: string;
}

interface Props {
  editModeTab: string;
  setEditMode: (value: string) => void;
}

const AdminReportCatalog: React.FC<Props> = ({ editModeTab, setEditMode }) => {
  const [originalData, setOriginalData] = useState<ReportDetails[]>([]);
  const [copyData, setCopyData] = useState<ReportDetails[]>([]);
  const [changedIndices, setChangedIndices] = useState<number[]>([]);
  const [editingReportId, setEditingReportId] = useState<number | null>(null);
  const [editModeDescription, setEditModeDescription] = useState<string>("");
  const [editModeContactEmail, setEditModeContactEmail] = useState<string>("");
  const [editModePersona, setEditModePersona] = useState<string>("");

  const [search, setSearch] = useState<string>("");
  const [searchResultCount, setSearchResultCount] = useState<number | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const response: AxiosResponse = await protectedAxiosInstance.get(
          `${window.location.origin}/api/Service/GetReportLibraryConfiguration`
        );
        const responseData = response.data.Table as ReportDetails[];

        setOriginalData(responseData);
        setCopyData([...responseData]);
      } catch (error) {
        console.error("Error loading data", error);
      }
    };
    loadData();
  }, []);

  const handleEdit = (id: number, index: number) => {
    setEditingReportId(id);
    const reportToEdit = copyData[index];
    setEditModePersona(reportToEdit.Persona);
    setEditModeDescription(reportToEdit.Description);
    setEditModeContactEmail(reportToEdit.ContactEmail);
  };

  const handleSave = (index: number) => {
    setCopyData((prevCopyData: ReportDetails[]) =>
      prevCopyData.map((report: ReportDetails, idx: number) =>
        idx === index
          ? {
              ...report,
              Persona: editModePersona,
              Description: editModeDescription,
              ContactEmail: editModeContactEmail,
            }
          : report
      )
    );

    if (!changedIndices.includes(index)) {
      setChangedIndices((prevIndices: number[]) => [...prevIndices, index]);
    }

    setEditingReportId(null);
  };

  const handleCancel = (index: number) => {
    setCopyData((prevCopyData: ReportDetails[]) =>
      prevCopyData.map((report: ReportDetails, idx: number) =>
        idx === index ? originalData[index] : report
      )
    );
    setEditingReportId(null);
  };

  const handleSaveAllChanges = async () => {
    const changedData = changedIndices.map((index) => copyData[index]);

    try {
      const response = await axios.put(
        `${window.location.origin}/api/Service/PutReportLibraryConfiguration`,
        { ReportDetails: changedData }
      );

      if (response.status === 200) {
        console.log("Changes saved successfully!");
        setOriginalData([...copyData]);
        setChangedIndices([]);
      }
    } catch (error) {
      console.error("Error saving data", error);
      console.log("Failed to save changes.");
    }
  };

  const searchTiletext = () => {
    setSearchResultCount(0);

    copyData.forEach((result: ReportDetails) => {
      if (
        result.TileText.toLowerCase().includes(search.toLowerCase()) ||
        result.Description.toLowerCase().includes(search.toLowerCase())
      ) {
        setSearchResultCount((prevCount) => (prevCount !== null ? prevCount + 1 : 1));
      }
    });
  };

  const columns: IColumn[] = [
    {
      key: "persona",
      name: "Persona",
      fieldName: "Persona",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: ReportDetails, index?: number) => (
        editingReportId === item.ID ? (
          <TextField
            className="text-cell-center"
            value={editModePersona}
            onChange={(e, newValue) => setEditModePersona(newValue || "")}
          />
        ) : (
          <div className="text-cell  text-cell-center">{item.Persona}</div>
        )
      ),
    },
    {
      key: "title",
      name: "Title",
      fieldName: "TileText",
      minWidth: 150,
      maxWidth: 300,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        <a href={item.ReportURL} target="_self" className="text-cell text-cell-center">
          {item.TileText}
        </a>
      ),
    },
    {
      key: "description",
      name: "Description",
      fieldName: "Description",
      minWidth: 200,
      maxWidth: 400,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        editingReportId === item.ID ? (
          <TextField
            className="text-cell-center"
            value={editModeDescription}
            onChange={(e, newValue) => setEditModeDescription(newValue || "")}
          />
        ) : (
          <div className="text-cell text-cell-center">{item.Description}</div>
        )
      ),
    },
    {
      key: "typeOfReport",
      name: "Type of Report",
      fieldName: "IsPowerBI",
      minWidth: 100,
      maxWidth: 200,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        <div className="text-cell text-cell-center">{item.IsPowerBI}</div>
      ),
    },
    {
      key: "contact",
      name: "Contact",
      fieldName: "ContactEmail",
      minWidth: 100,
      maxWidth: 130,
      isResizable: true,
      onRender: (item: ReportDetails) => (
        editingReportId === item.ID ? (
          <TextField
            className="text-cell-center"
            value={editModeContactEmail}
            onChange={(e, newValue) => setEditModeContactEmail(newValue || "")}
          />
        ) : (
          <div className="text-cell text-cell-center">{item.ContactEmail}</div>
        )
      ),
    },
    {
      key: "actions",
      name: "Actions",
      fieldName: "Actions",
      minWidth: 130,
      maxWidth: 145,
      isResizable: true,
      onRender: (item: ReportDetails, index?: number) => (
        editingReportId === item.ID ? (
          <>
            <Button 
              onClick={() => handleSave(index!)}
              title="Save changes"
              aria-label="Save changes"
              icon={<Save16Regular />} 
              appearance="secondary"
              style={{margin: "10px"}}
            />
            <Button 
              onClick={() => handleCancel(index!)}
              title="Discard changes"
              aria-label="Discard changes"
              icon={<Dismiss16Regular />} 
              appearance="secondary"
              style={{margin: "10px"}}

            />
          </>
        ) : (
          <Button 
            onClick={() => handleEdit(item.ID, index!)}
            title="Edit"
            aria-label="Edit"
            icon={<Edit16Regular />} 
            appearance="secondary"
            style={{margin: "10px"}}
          />
        )
      ),
    },
  ];

  const detailsListStyles: Partial<IDetailsListStyles> = {
    headerWrapper: {
      marginTop: "-12px",
      selectors: {
        ".ms-DetailsHeader": {
          backgroundColor: "#505050",
        },
        ".ms-DetailsHeader-cellName": {
          color: "white",
        },
        ".ms-DetailsHeader-cell:hover": {
          backgroundColor: "#505050",
        },
      },
    },
    
  };

  return (
    <div className="ReportContainer" style={{height: "65%"}}>
      <div className="inputContainer catalogContainer">
        <h5>Search Reports</h5>
        <input
          type="text"
          placeholder="Search Reports by Name or Keyword"
          aria-label="Search Reports"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="SearchBoxInput SearchBoxInputBox CatalogSearchbox"
          onKeyUp={searchTiletext}
          style={{ marginBottom: "12px" }}
        />
        <div className="searchMagnifierIcon" id="CatalogSearchIcon" title="Search Partners"></div>
      </div>
      <div className="ExternalReportContainer" style={{height: "100%"}}>

        <DetailsList
          items={copyData.filter(
            (report) =>
              search.length === 0 ||
              report.TileText.toLowerCase().includes(search.toLowerCase()) ||
              report.Description.toLowerCase().includes(search.toLowerCase())
          )}
          columns={columns}
          setKey="set"
          layoutMode={DetailsListLayoutMode.justified}
          selectionMode={SelectionMode.none}
          styles={detailsListStyles}
        />

        {search.length !== 0 && searchResultCount === 0 && (
          <div className="ErrorData ErrorDataMessageDynamics">
            <center>
              <div className="SearchResultsEmoticon">No Data :(</div>
            </center>
          </div>
        )}

      </div>
      <div className="SaveAllContainer">
        <PrimaryButton disabled={changedIndices.length > 0} className="custom-button" onClick={handleSaveAllChanges}>Save All Changes</PrimaryButton>
      </div>
    </div>
  );
};

export default AdminReportCatalog;