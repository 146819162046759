import React, { useEffect, useState } from "react";
import { Container, Card, Table } from "react-bootstrap";
import "./OCPLanding.css"; // Import global styles
import clock from "../../Images/img/clock.png";
import Loading from '../../Images/Loading.gif'
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client';
import { Constants } from "../helpers/Constants";

interface props {
  isTokenAvailable: boolean;
}

const Notifications: React.FC<props> = ({ isTokenAvailable }) => {
  const notificationPowerBiTile = "notificationPowerBiTile";
  const [EMBED_POWERBI_URL, setEMBED_POWERBI_URL] = useState<string>("");

  useEffect(() => {
    const env = window.location.hostname;
    if(env.includes("localhost") || env.includes("dev")){
        setEMBED_POWERBI_URL(Constants.NOTIFICATION_EMBED_URL);
    } else if(env.includes("ftbiuat")){
        setEMBED_POWERBI_URL(Constants.NOTIFICATION_EMBED_URL_UAT);
    } else {
        setEMBED_POWERBI_URL(Constants.NOTIFICATION_EMBED_URL_PROD);
    }
        
  }, []);

  return (
    // <Container className="notifications-component" style={{padding:'0px'}}>
    <Card className="p-4 notification" style={{ border: "none" }}>
      <div className="d-flex align-items-center mb-3">
        <img src={clock} alt="Clock Icon" className="icon" />
        <h5 className="ml-2 mb-0 headingnotifications">Recent Notifications</h5>
      </div>
      <div  style={{width:'100%'}}>
      {isTokenAvailable && (
        <PowerBIEmbed
          embedConfig={{
            type: "report",
            embedUrl: EMBED_POWERBI_URL,
            accessToken: localStorage.getItem("powerbi_access_token") || "",
            tokenType: models.TokenType.Aad,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              navContentPaneEnabled: false,
              filterPaneEnabled: false,
              persistentFiltersEnabled: false,
              personalBookmarksEnabled: false,
              bars: {
                actionBar: {
                  visible: false,
                },
              },
            },
          }}
          cssClassName={notificationPowerBiTile}
        />
      )}
      {!isTokenAvailable && (
        // loading icon
        <div className="NoNotification">
          <img className="landingpage-loadingIcon" src={Loading} />
        </div>
      )}
      </div>
    </Card>
    // </Container>
  );
};

export default Notifications;
