import React, { useState, useEffect } from "react";
import {
  ILatestNewLetterLink,
  INewLetterLink,
  IQuickLink,
  IRecentReports,
  IResponse,
} from "../Interfaces/Interfaces";
import { Constants } from "../helpers/Constants";
import FastTrackOKR from "../../Images/FastTrackOKR.png";
import onboarding from "../../Images/Onboarding.png";
import MIDAs from "../../Images/MIDAsImage.jpg";
import Loading from "../../Images/Loading.gif";
import VideoImage from "../../Images/VideoImage.jpg";
import userImageDefault from "../../Images/NoProfileUser.png";
import axios from "axios";
// import '../Styles/external/bootstrapv4.1.1.min.css'
// import '../Styles/LandingPage.css';
import "../../Styles/LandingPage.css";
// import '../Styles/app.css';
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
//import Header from './Header'; // Adjust the path according to your file structure
import UnifiedComponent from "./UnifiedComponent";
import "./OCPLanding.css";
import Header from "./Header";
import LeftComponent from "./LeftComponent";
import Notifications from "./Notifications";
import { Container } from "react-bootstrap";
import { isTokenExpiry, signOut } from "../helpers/Common";
import protectedAxiosInstance from "../helpers/api";


//import LeftComponent from './LeftComponent';
//import Notifications from './Notifications'; // If it's Notification, correct as necessary

const OCPLandingV2 = () => {
  const [idToken, setIdToken] = useState<string>("");
  const [newLinks, setNewLinks] = useState<INewLetterLink[]>([]);
  const [quickLinks, setQuickLinks] = useState<IQuickLink[]>([]);
  const [activeLinks, setActiveLinks] = useState<INewLetterLink[]>([]);
  const [newslettersLinks, setNewslettersLinks] = useState<
    ILatestNewLetterLink[]
  >([]);
  const [latestNewLetterLinks, setLatestNewLetterLinks] = useState<
    ILatestNewLetterLink[]
  >([]);
  const [loggedInUser, setLoggedInUser] = useState<string>("");
  const [userImg, setUserImg] = useState<string>(userImageDefault);
  const [isAlertExpanded, setIsAlertExpanded] = useState<boolean>(false);
  const [isLandingPageDataLoaded, setIsLandingPageDataLoaded] =
    useState<boolean>(false);
  const [isBannerPresent, setIsBannerPresent] = useState<boolean>(false);
  const [bannerType, setBannerType] = useState<number>(0);
  const [bannerText, setBannerText] = useState<string>("");
  const [recentreports, setRecentreports] = useState<IRecentReports[]>([]);
  const [moreThanOneLinks, setMoreThanOneLinks] = useState<boolean>(false);
  const [moreThanOneNewsLetterLinks, setMoreThanOneNewsLetterLinks] =
    useState<boolean>(false);
  const [searchT, setSearchT] = useState<string>("");
  const [currentPopupID, setCurrentPopupID] = useState<string>("");
  const [searchArrayT, setSearchArrayT] = useState<string[]>([]);
  const [load, setLoad] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>("");
  const notificationPowerBiTile = "notificationPowerBiTile";
  const [isTokenAvailable, setIsTokenAvailable] = useState<boolean>(false);
  const GetDataWithconfigId = async (configId: string) => {
    try {
      const response = await protectedAxiosInstance.post(
        "api/Service/GetDataWithConfiguration",
        {
          configID: configId,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      LoadLandingPageData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const setPopup = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    if (searchT) {
      setCurrentPopupID("searchReportResults");
    }
  };

  const getRecentReports = (ReportString: string) => {
    const reportsobj = ReportString;
    const tempArray: IRecentReports[] = [];
    if (reportsobj.length > 0) {
      const reportItems = reportsobj.split("$|$");
      for (const reportDetails of reportItems) {
        const report = reportDetails.split("#|#");
        const reportitem = {
          tileID: report[0],
          tileText: report[1],
        };

        tempArray.push(reportitem);
      }
    }
    // remove element which has empty tileID
    tempArray.forEach((item, index) => {
      if (item.tileID === "" || item.tileText === "") {
        tempArray.splice(index, 1);
      }
    });
    setRecentreports(tempArray);
  };

  const getURL = (reportUrl: string): string => {
    let url = reportUrl;
    if (reportUrl === "https://ftbi.microsoft.com/") {
      url = Constants.FTBI_PROD_URL;
    } else if (reportUrl === "https://ftbiuat.microsoft.com/") {
      url = Constants.FTBI_UAT_URL;
    } else if (
      reportUrl.includes("ftbi.microsoft.com") ||
      reportUrl.includes("ftbiuat.microsoft.com")
    ) {
      let reportId = reportUrl.split("=")[1];
      let parent;
      if (reportId.includes("_")) {
        parent = reportId.split("_")[0];
        url =
          window.location.origin +
          "/view?parent=" +
          parent +
          "&tileId=" +
          reportId;
      } else {
        url = window.location.origin + "/view?parent=" + reportId;
      }
    }
    return url;
  };

  const LoadLandingPageData = (response: IResponse) => {
    setIsLandingPageDataLoaded(true);
    if (response.data) {
      if (response.data.Table && response.data.Table.length > 0) {
        setIsBannerPresent(true);
        setBannerText(response.data.Table[0]["Text"]);
        setBannerType(response.data.Table[0]["BannerType"]);
      }
      if (response.data.Table2 && response.data.Table2.length > 0) {
        const newLinksData = response.data.Table2;
        if (newLinksData.length > 1) {
          setMoreThanOneLinks(true);
        }

        const newLinksArray = newLinksData.map((item: any) => {
          let desc: string[] = [];
          if (item.Description != null) {
            desc = item.Description.split("$|$");
          }
          return {
            tileText: item.tileText,
            tileId: item.tileId,
            isHub: item.isHub,
            URL: item.URL,
            Image: item.Image,
            Description: desc,
          };
        });

        setNewLinks(newLinksArray);

        const activeLinksArray = newLinksArray.filter(
          (item: any) => item.isActive === 1
        );
        setActiveLinks(activeLinksArray);
      }
      // set quick links data
      if (response.data.Table3 && response.data.Table3.length > 0) {
        const quickLinksData = response.data.Table3;
        const quickLinksArray = quickLinksData.map((item: any) => ({
          Title: item.Title,
          URL: getURL(item.URL),
          Description: item.Description,
        }));
        setQuickLinks(quickLinksArray);
      }
      // set newsletter data
      if (response.data.Table4 && response.data.Table4.length > 0) {
        const lettersLinksData = response.data.Table4;
        let moreThanOneNewsLetterLinksFlag = false;
        if (lettersLinksData.length > 1) {
          moreThanOneNewsLetterLinksFlag = true;
        }
        const newslettersLinksArray = lettersLinksData.map(
          (item: any, index: number) => {
            return {
              Title: item.Title,
              Image: item.Image,
              URL: item.URL,
              Date: item.NewsletterDate,
              Description: item.Description,
            };
          }
        );

        setNewslettersLinks(newslettersLinksArray);
        setMoreThanOneNewsLetterLinks(moreThanOneNewsLetterLinksFlag);
        if (lettersLinksData.length > 0) {
          const latestNewLetterLink = {
            Title: lettersLinksData[0].Title,
            Image: lettersLinksData[0].Image,
            URL: lettersLinksData[0].URL,
            Date: lettersLinksData[0].LongDate,
            Description: lettersLinksData[0].Description,
          };
          setLatestNewLetterLinks([latestNewLetterLink]);
        }
      }
    }
  };

  const hubQuickLinkClicked = (tileId: string) => {
    window.location.href = `/?tab=${tileId}`;
  };

  const AlertExpandClick = () => {
    setIsAlertExpanded((prev) => !prev);
  };

  const getCognitiveSearchKey = async () => {
    try {
      const response = await protectedAxiosInstance.get("api/service/GetKeyVaultURL", {
        withCredentials: true,
      });
      setSearchKey(response.data.CognitiveSearchKey);
    } catch (error) {
      console.error("OCPLandingV2 KeyVaultURL error", error);
    }
  };

  const loadRecentReports = async () => {
    try {
      const response = await protectedAxiosInstance.get("api/service/GetRecentReports", {
        withCredentials: true,
      });
      let reportany = response.data.Table[0].RecentReports;
      let ReportString = reportany;
      getRecentReports(ReportString);
    } catch (error) {
      console.error("OCPLandingV2 Recent Reports error", error);
    }
  };

  const fetchPowerBiToken = async () => {
    const pbiToken = await protectedAxiosInstance.get(
      `${window.location.origin}/api/Service/getPowerBIToken?regenerate=true`,
      {
        withCredentials: true,
      }
    );
    localStorage.setItem("powerbi_access_token", pbiToken.data);
  };

  useEffect(() => {
    const userImage1 = sessionStorage.getItem("UserImage") || userImageDefault;
    userImage1.length > 0 && setUserImg(userImage1);
    const userDetails = localStorage.getItem("user");
    const userName = userDetails ? JSON.parse(userDetails).displayName : "";
    setLoggedInUser(userName);

    loadRecentReports();
    GetDataWithconfigId(Constants.LANDING_PAGE_CONFIG_ID);

    // check if token is not expired
    if (!isTokenExpiry()) {
      setIsTokenAvailable(true);
    } else {
      signOut();
    }
  }, []);

  const getBanner = () => {
    return (
      <div
        className={
          isAlertExpanded
            ? `FreezBannermore${bannerType}`
            : `FreezBannerless${bannerType}`
        }
      >
        <span
          className={
            bannerText.length > 200
              ? isAlertExpanded
                ? "btnReadLess"
                : "btnReadMore"
              : "noBtn"
          }
          onClick={() => AlertExpandClick()}
        >
          &nbsp;
        </span>
        <div
          className={isAlertExpanded ? "fulltext" : "ellipsetext"}
          dangerouslySetInnerHTML={{ __html: bannerText }}
        ></div>
      </div>
    );
  };
  const tmpEmbedConfig: any = {
    type: "report",
    id: "ff57ea8f-d2fc-4618-a5ad-60819effd602",
    accessToken: localStorage.getItem("powerbi_access_token"),
    embedUrl:
      "https://msit.powerbi.com/groups/c7300506-4e4b-47d2-8d0f-bfdfd6897ec1/reports/ff57ea8f-d2fc-4618-a5ad-60819effd602/ReportSection39f5133a7ce802c107ab?experience=power-bi",
    settings: {
      navContentPaneEnabled: false,
      filterPaneEnabled: false,
      persistentFiltersEnabled: false,
      personalBookmarksEnabled: false,
      bars: {
        actionBar: {
          visible: false,
        },
      },
    },
  };
  return (
    <>
      <div className="mainContainer">
        {isLandingPageDataLoaded && isBannerPresent && getBanner()}
        
        <Header userImg={userImg} loggedInUser={loggedInUser} />
        <UnifiedComponent recentReports={recentreports} />
        
        <Container fluid className="d-flex col bottom-row">
          <LeftComponent
            isLandingPageDataLoaded={isLandingPageDataLoaded}
            latestNewLetterLinks={latestNewLetterLinks}
          />
          <Notifications isTokenAvailable={isTokenAvailable} />
        </Container>
        
      </div>
      
    </>
  );
};

export default OCPLandingV2;
